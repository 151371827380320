import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Moment from "moment"
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver'
import Img from "gatsby-image"
import Button from "../components/button"

export const query = graphql`
query blogTemplateQuery($uid: String)  {
    prismic {
      allPosts(uid: $uid) {
        edges {
          node {
            post_body
            title
            _meta {
              firstPublicationDate
              uid
              type
            }
            author {
              ... on PRISMIC_Staff {
                first_name
                _meta {
                type
                  uid
                }
                profile_photo
                profile_photoSharp{
                    childImageSharp {
                        fluid(maxWidth: 40, maxHeight: 40) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                }
              }
            }
            guest_author
            main_image
            main_imageSharp{
                childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
            }
            featured
          }
        }
      }
    }
  }
`
export default (props) => {
  const doc = props.data.prismic.allPosts.edges[0];
  if (!doc) return null;
  return (<div className="body">
    <Layout>
      <SEO title={doc.node.title[0].text} />
      <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
        <div className="section">
          <div className="preview blog"><div className="card blog w-inline-block"><div className="card-image template" ><Img fluid={doc.node.main_imageSharp.childImageSharp.fluid} alt="Post" /></div></div>
            <div className="preview">
              <h1 className="heading">{doc.node.title[0].text}<br /></h1>
              <div className="blog-details">
                {
                  doc.node.author != null ?
                    <div><div className="blog-author-image"><Img fluid={doc.node.author.profile_photoSharp.childImageSharp.fluid} alt="Author" /></div><Link to={linkResolver(doc.node.author._meta)} className="text-link"><strong>{doc.node.author.first_name[0].text}</strong></Link> on {Moment(Date.parse(doc.node._meta.firstPublicationDate)).format("LL")}</div>
                    :
                    doc.node.guest_author != null ?
                      <div><strong>{doc.node.guest_author[0].text}</strong> on {Moment(doc.node._meta.firstPublicationDate).format("LL")}</div>
                      :
                      <div><strong>Anonymous</strong> on {Moment(doc.node._meta.firstPublicationDate).format("LL")}</div>
                }
              </div>
            </div>
          </div>
          <div className="blog-post-body w-richtext">
            {RichText.render(doc.node.post_body)}
          </div>
          <div className="button-parent page">
            <Button name="Back To Blog" to="/blog/" />

          </div>
        </div>
      </div>
    </Layout>
  </div>
  )
}

